import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Col, Container, Row, Alert, Label,  Card,
  CardBody, } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
// import images
//import logodark from "../../assets/images/logo-dark.png";
import logodark from "../../components/Print/printlogo.png"
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";
// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";
import Loader from "pages/loader";
import axios from "axios";


class Login extends Component {





  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      prov : false,
      type : "password",
      loading : false,
      ipaddress : ''
    };
    this.obj = this;
   
    this.API_URL_SAVE =   "UserLogin/0/token";
    this.pushFormName = "/persontoperson";
    this.show =  this.show.bind(this);
  }

  componentDidMount() {
    this.props.apiError("");
    this.getip();
  }


  getip = async()=> {
    
      const res = await axios.get("https://api.ipify.org/?format=json");
      this.setState({ipaddress : res.data.ip});
  } 




  signIn = (res, type) => {
    const { socialLogin } = this.props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    }
  };

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google");
  };

  //handleTwitterLoginResponse
  twitterResponse = () => {};

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook");
  };


  show() {

    if (this.state.type == "password")
      {
        this.setState({
        type : "text"
        });
      }

      else {
        this.setState({
          type : "password"
          });
      }
      
    
  }




  render() {
    return (
      <React.Fragment>
        <div>
        {this.state.loading ? <Loader /> : null}
          <MetaTags>
            <title>Login | RSEVA KENDRA</title>
          </MetaTags>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />
           
              <Col xl={3} >
                <div  className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="dashboard" className="d-block auth-logo">
                          <img
                            src={logodark}
                            alt=""
                            height="120"
                            className="auth-logo-dark"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="18"
                            className="auth-logo-light"
                          />
                        </Link>
                      </div>

                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p className="text-muted">
                            Sign in to continue.
                          </p>
                        </div>

                        <div className="mt-4">
                        {this.props.error && this.props.error ? (
                      <Alert color="danger">{this.props.error}</Alert>
                    ) : null}
                   
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email:
                            (this.state && this.state.email) ||
                            "",
                          password:
                            (this.state && this.state.password) || "",
                            ipaddress : this.state.ipaddress
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required(
                            "Please Enter Your Email"
                          ),
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                        })}
                        onSubmit={values => {
                        //  this.setState({loading : true});
   
                          // let vformData = new FormData();
                          // //Information
                          // vformData.append("UserName", values.email);
                          // vformData.append("UserPassword", values.password);


                          // Fn_UserLogin(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true);
                        
                         this.props.loginUser(values, this.props.history, this.state.ipaddress);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                UserName
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password"
                                  type={this.state.type}
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.password && touched.password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={this.show}
                                >

                                  <i className="mdi mdi-eye-outline"></i>
                                </button>
                              </div>
                              <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                            </div>
                            
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>

                         
                            <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div>
                          </Form>
                        )}
                      </Formik>

                          <div className="mt-5 text-center">
                            <p>
                              Don&apos;t have an account ?{" "}
                              <a
                                href="pages-register-2"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Signup now{" "}
                              </a>{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} © rsevakendra.in.
                          <i className="mdi mdi-heart text-danger"></i> All rights Reserved
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);

